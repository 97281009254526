







































import Vue from "vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import jsonAPI from "@/api";
import { AxiosResponse } from "axios";
dayjs.extend(relativeTime);

interface Log {
  id: string;
  event: string;
  action: string;
  actionType: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

interface ServerResponse {
  data: Array<Log>;
}

export default Vue.extend({
  name: "LatestAutomations",
  data() {
    return {
      logs: [] as Array<Log>,
    };
  },
  mounted() {
    jsonAPI.get("automationLog?per_page=3").then((response: AxiosResponse) => {
      if (response && response.data) {
        const data = response.data as ServerResponse;
        this.logs = data.data;
      }
    });
  },
  methods: {
    timeAgo(log: Log) {
      return dayjs(log.createdAt).fromNow();
    },
  },
});
