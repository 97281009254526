import { render, staticRenderFns } from "./CustomerTeaser.vue?vue&type=template&id=57729586&scoped=true&"
import script from "./CustomerTeaser.vue?vue&type=script&lang=ts&"
export * from "./CustomerTeaser.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57729586",
  null
  
)

export default component.exports