export default [
  {
    key: 'install_plugin',
    status: false,
    name: 'Plugin installed',
    inactiveDescription:
      'Automation.app has not yet registered that you have installed our plugin. If you need help contact our support or download the plugin again',
    activeDescription: 'The automation.app plugin has been installed',
    title: 'Download plugin',
    routePath: '/orders'
  },
  {
    key: 'import_orders',
    status: false,
    name: 'Order Import',
    inactiveDescription: "<span>Automation.app has not received any orders yet. Have you installed the <a href='organization/integrations'>plugin</a></span>",
    activeDescription: 'Last order recieved from your webshop ',
    title: 'View orders',
    routePath: '/orders'
  },
  {
    key: 'import_products',
    status: false,
    name: 'Product Import',
    inactiveDescription: 'Automation.app doesn’t have access to your products, in order to deliver a good experience we need to sync your product catalog',
    activeDescription: 'Product data is being synced from your webshop',
    title: 'Product Imports',
    routePath: '/organization/integrations'
  },
  {
    key: 'crm',
    status: false,
    name: 'CRM',
    inactiveDescription: 'Your customers will automatically be created when you receive an order.',
    activeDescription: 'Access your CRM overview and see individual clients interactions',
    title: 'CRM',
    routePath: '/customers'
  },
  {
    key: 'create_filter',
    status: false,
    name: 'Filtration',
    inactiveDescription: 'Create filters that can be used in order overviews',
    activeDescription: 'Update the filters for your order overview',
    title: 'Filtration',
    routePath: '/organization/segments/create'
  },
  {
    key: 'create_view',
    status: false,
    name: 'Views',
    inactiveDescription: 'Create a custom view to to see the information that is relevant for your business',
    activeDescription: 'Update your views to see relevant order data (Viewed columns)',
    title: 'Views',
    routePath: '/organization/view/create'
  },
  {
    key: 'create_email_template',
    status: false,
    name: 'Email template',
    inactiveDescription: 'In order to communicate with customers let`s set up your email template',
    activeDescription: 'Create more email templates',
    title: 'Email template',
    routePath: '/organization/email-template'
  },
  {
    key: 'configure_driver',
    status: false,
    name: 'Email Sender',
    inactiveDescription: 'Let Automation.app send on your behalf. Let`s validate that we have the right data',
    activeDescription: 'Update your email sender information or configure SMTP information',
    title: 'Email Sender',
    routePath: '/organization/email'
  },
  {
    key: 'create_automation',
    status: false,
    name: 'Automation',
    inactiveDescription: 'Set up automations: Send emails to customers and customise workflows',
    activeDescription: 'Set up new automations: send email to customers and customise workflows',
    title: 'Automations',
    routePath: '/Automations/create'
  },
  {
    key: 'invite_teammates',
    status: false,
    name: 'Team',
    inactiveDescription: 'Invite your team to start collaboration around workflows and customer engagement',
    activeDescription: 'With your team invited your can share views and filters based on team roles and much more',
    title: 'Invite colleagues',
    routePath: '/organization/invite'
  }
  // {
  //     'key': 'create_subscription',
  //     'status': false,
  //     'name': 'Subscription',
  //     'inactiveDescription': 'Enter your billing details and pick a plan to increase the functionality of Automation.app',
  //     'activeDescription': 'Thank you for choosing Automation.app',
  //     'title': 'Subscribe',
  //     'routePath': '/organization/subscription/create'
  // },
  // {
  //     'key': 'install_tracking',
  //     'status': false,
  //     'name': 'Tracking',
  //     'inactiveDescription': 'Install tracking your marketing',
  //     'activeDescription': '',
  //     'title': 'Install Tracking',
  //     'routePath': ''
  // }
]
