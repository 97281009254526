<template>
  <div class="p-4 border rounded box-shadow bg-gradient-light mb-6">
    <div class="d-flex">
      <div class="d-flex justify-content-center align-items-center">
        <InfoCircle :height="50" />
      </div>
      <div class="flex-grow-1">
        <h3 class="h3 p-3 mb-0 font-weight-bold">{{ name }}</h3>
        <p class="p-3 align-middle mb-0">
          {{ description }}
        </p>
      </div>
      <div class="d-flex justify-content-center align-items-center pl-3">
        <router-link :to="link" class="btn btn-primary p-3 alert-link">{{
          linkText
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import InfoCircle from "../Svg/InfoCircle.vue";
export default {
  name: "Alert",
  props: {
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    linkText: {
      type: String,
      default: "",
    },
  },
  components: { InfoCircle },
};
</script>

<style lang="scss" scoped>
.alert-link {
  width: 170px;
}
</style>
