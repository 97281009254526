












import { Component, Prop, Vue } from 'vue-property-decorator';
import Avatar from "@/components/Svg/Avatar.vue";
import type { Customer } from '@/types/customer';

@Component({
  components: {
    Avatar
  }
})
export default class extends Vue {
  @Prop() customer!: Customer;
}
