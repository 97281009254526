




















import { Component, Prop, Vue } from 'vue-property-decorator';
import type { MessageTeaser } from '@/types/messageTeaser';
import Sms from "../Svg/Sms.vue";
import Email from "../Svg/Email.vue";

@Component({
  components: {
    Sms,
    Email,
  },
  computed: {
    messageText() {
      let text = this.$props.message.message;
      if (text.length > 200) {
        text = text.substring(0, 200) + '...';
      }
      return text;
    },
    messageSubject() {
      let text = this.$props.message.subject;
      if (!text) {
        text = 'New message'
      }
      if (text.length > 50) {
        text = text.substring(0, 50) + '...';
      }
      return text;
    },
    customerName() {
      let name = this.$props.message.customer.first_name + ' ' + this.$props.message.customer.last_name;
      return name;
    }
  }
})
export default class extends Vue {
  @Prop() message!: MessageTeaser;
}
