




























import Vue from "vue";
import { mapGetters } from "vuex";
import onboarding from "@/helpers/onboarding";
import { OnboardingStep } from "@/types/organization";
import LogoWithPackage from "@/components/Svg/LogoWithPackage.vue";

export default Vue.extend({
  name: "IncompleteSteps",
  components: {
    LogoWithPackage,
  },
  computed: {
    ...mapGetters(["getOrganization"]),
    getIncompleteSteps() {
      // check if organization has been loaded and check if onboarding array exists
      if (
        this.getOrganization &&
        this.getOrganization.settings !== null &&
        "onboarding" in this.getOrganization.settings
      ) {
        const newSteps = onboarding.filter(
          (step: OnboardingStep) =>
            !this.getOrganization.settings.onboarding.some(
              (completeStep: OnboardingStep) => step.key === completeStep.key
            )
        );
        // disable crm step from the incomplete steps
        return newSteps.filter((step: OnboardingStep) => step.key !== "crm")[0];
      } else {
        return onboarding[0];
      }
    },
  },
});
