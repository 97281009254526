<template>
  <div class="avatar d-flex justify-content-center align-items-center" :style="avatarSize">
    <img v-if="avatarImage" :src="avatarImage" alt="avatar" />
    <span class="initials" :style="fontDisplaySize" v-else>
      {{ makeInitials() }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Avatar",
  props: {
    avatarImage: {
      type: String,
      default: "",
    },
    firstName: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    customerType: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "75",
    },
    fontSize: {
      type: String,
      default: "22",
    }
  },
  data() {
    return {
      colors: [
        "#1fbc9c",
        "#2ecc70",
        "#3398db",
        "#a463bf",
        "#3d556e",
        "#f2c511",
        "#e84b3c",
        "#2ecc70",
        "#3398db",
        "#a463bf",
        "#3d556e",
        "#f2c511",
        "#e84b3c",
      ],
    }
  },
  computed: {
    avatarSize() {
      // const index = Math.floor(Math.random() * 10) + 1;
      if(this.customerType === 'prospect') {
        var backgroundColor = 'rgba(137, 243, 129, 0.5)';
      } else {
        backgroundColor = '#3398db';
      }
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        'background-color': backgroundColor
      };
    },
    fontDisplaySize() {
      if(this.customerType === 'prospect') {
        var textColor = '#333333';
      } else {
        textColor = '#ffffff';
      }
      return {
        'font-size': `${this.fontSize}px`,
        'color': textColor
      };
    },
  },
  methods: {
    makeInitials() {
      if(this.firstName && this.lastName) {
        const firstInitial = this.firstName.split("")[0];
        const lastInitial = this.lastName.split("")[0];
        return `${firstInitial}${lastInitial}`;
      }else if(!this.firstName) {
        const lastInitial = this.lastName.split("")[0];
        return `${lastInitial}`;
      } else if(!this.lastName) {
        const firstInitial = this.firstName.split("")[0];
        return `${firstInitial}`;
      } else {
        return 'AV';
      }
    },
    getRandomColor: function (index) {
      return this.colors[index];
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  background-color: lightblue;
  font-size: 22px;
  border-radius: 100%;
  text-transform: uppercase;
  overflow: hidden;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.initials {
  box-sizing: border-box;
  line-height: 2rem;
  width: 3rem;
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}
</style>
