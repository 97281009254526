















import { Component, Prop, Vue } from 'vue-property-decorator';
import MoneyFormat from "vue-money-format";
import type { OrderTeaser } from '@/types/orderTeaser';

@Component({
  components: {
    MoneyFormat
  }
})
export default class extends Vue {
  @Prop() order!: OrderTeaser;
  @Prop() currency!: string;
}
