
















import { Component, Prop, Vue } from 'vue-property-decorator';
import MoneyFormat from "vue-money-format";
import type { ProductTeaser } from '@/types/productTeaser';

@Component({
  components: {
    MoneyFormat
  },
  computed: {
    productName() {
    let product_name = this.$props.product['name'];
    if (!product_name) {
      product_name = 'Product name'
    }
    if (product_name.length > 40) {
      product_name = product_name.substring(0, 40) + '...';
    }
    return product_name;
  },
  }
})
export default class extends Vue {
  @Prop() product!: ProductTeaser;
  @Prop() currency!: string;
}
