



















































































































import Vue from "vue";
import dayjs from "dayjs";
import Chart from 'chart.js/auto';
import MoneyFormat from "vue-money-format";
import { AxiosResponse } from "axios";
import { mapGetters } from "vuex";
import Default from "@/layouts/default.vue";
import jsonAPI from "@/api";
import Sidebar from "@/components/Sidebar.vue";
import IncompleteSteps from "@/components/IncompleteSteps.vue";
import LogoWithPackage from "@/components/Svg/LogoWithPackage.vue";
import LatestAutomations from "@/components/LatestAutomations.vue";
import Alert from "@/components/UI/Alert.vue";
import onboarding from "@/helpers/onboarding";
import { OnboardingStep } from "@/types/organization";
import CustomerTeaser from "@/components/dashboard/CustomerTeaser.vue";
import OrderTeaser from "@/components/dashboard/OrderTeaser.vue";
import MessageTeaser from "@/components/dashboard/MessageTeaser.vue";
import ProductTeaser from "@/components/dashboard/ProductTeaser.vue";
import type { MessageTeaser as MessageTeaserType } from '@/types/messageTeaser';
import type { OrderTeaser as OrderTeaserType } from '@/types/orderTeaser';
import type { ProductTeaser as ProductTeaserType } from '@/types/productTeaser';
import InfoCircle from "@/components/Svg/InfoCircle.vue";

interface CustomerType {
  id: string;
  first_name: string;
  last_name: string;
  telephone: string;
  email: string;
}
interface Reports {
  order: {
    count: number;
    labels: string;
    data: Array<string>;
  };
  customer: {
    count: number;
    labels: string;
    data: Array<string>;
  };
  automation: {
    count: number;
    labels: string;
    data: Array<string>;
  };
  segment: {
    count: number;
    labels: string;
    data: Array<string>;
  };
  activity: {
    count: number;
    labels: string;
    data: Array<string>;
  };
  latestCustomers: Array<CustomerType>;
  latestMessages: Array<MessageTeaserType>;
  latestOrders: Array<OrderTeaserType>;
  productsSold: Array<ProductTeaserType>;
  currency: string;
}
interface DataSet {
  label: string;
  data: [];
  borderColor: string;
  fill: boolean;
  cubicInterpolationMode: string;
  tension: number;
}
interface Sales {
  labels: string[],
  datasets: DataSet[]
}

export default Vue.extend({
  name: "Overview",
  components: {
    Default,
    IncompleteSteps,
    LatestAutomations,
    LogoWithPackage,
    Sidebar,
    Alert,
    MoneyFormat,
    CustomerTeaser,
    OrderTeaser,
    MessageTeaser,
    ProductTeaser,
    InfoCircle,
},
  async created() {
    const response: AxiosResponse = await jsonAPI.get("user/current");
    return {
      account: response.data,
    };
  },
  data() {
    return {
      layout: "Default",
      config: {
        altFormat: "Y-m-d",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
        maxDate: "today",
      },
      range: `${dayjs()
        .subtract(366, "days")
        .format("YYYY-MM-DD")} to ${dayjs().format("YYYY-MM-DD")}`,
      gridData: {
        labels: [],
        datasets: [],
      } as Sales,
      sales: 0,
      revenue: 0,
      averageRevenue: 0,
      salesGraphConfig: {
        type: 'line',
        data: [],
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Sales'
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Count'
              }
            }
          }
        },
      },
      revenueGraphConfig: {
        type: 'line',
        data: [],
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Revenue'
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Value'
              }
            }
          }
        },
      },
      reportsLoading: true,
      // getCompletedSteps: 0,
      // getCompletionPercentage: 0,
      reports: {
        order: {
          count: 0,
          labels: "",
          data: [],
        },
        customer: {
          count: 0,
          labels: "",
          data: [],
        },
        automation: {
          count: 0,
          labels: "",
          data: [],
        },
        segment: {
          count: 0,
          labels: "",
          data: [],
        },
        activity: {
          count: 0,
          labels: "",
          data: [],
        },
        latestCustomers: [],
        latestMessages: [],
        latestOrders: [],
        productsSold: [],
      },
      currency: "",
      account: {
        id: "",
        name: "",
        email: "",
        role: 1,
        title: "",
        impersonation: false,
        organization: null,
      },
      lastOrder: "",
    };
  },
  computed: {
    ...mapGetters(["getOrganization"]),
    getIncompleteSteps(): OnboardingStep {
      // check if organization has been loaded and check if onboarding array exists
      if (
        this.getOrganization &&
        this.getOrganization.settings !== null &&
        "onboarding" in this.getOrganization.settings
      ) {
        const newSteps = onboarding.filter(
          (step: OnboardingStep) =>
            !this.getOrganization.settings.onboarding.some(
              (completeStep: OnboardingStep) => step.key === completeStep.key
            )
        );
        // disable crm step from the incomplete steps
        return newSteps.filter((step: OnboardingStep) => step.key !== "crm")[0];
      } else {
        return onboarding[0];
      }
    },
    getCompletedSteps(): number {
      if (
        this.getOrganization &&
        this.getOrganization.settings !== null &&
        "onboarding" in this.getOrganization.settings
      ) {
        const steps = this.getOrganization.settings.onboarding;
        const completed: string|OnboardingStep[] = [];
        steps.filter((step: OnboardingStep) => {
          if (step.status === true) {
            completed.push(step);
          }
        });
        // If account has atleast one order, push the crm step to increase count
        if (this.$data.lastOrder) {
          completed.push({
            key: 'crm',
            status: false,
            name: 'CRM',
            inactiveDescription: 'Your customers will automatically be created when you receive an order.',
            activeDescription: 'Access your CRM overview and see individual clients interactions',
            title: 'CRM',
            routePath: '/customers'
          });
        }
        return completed.length ?? 0;
      }
      return 0;
    },
    getInstallPluginStatus(): boolean {
      if (
        this.getOrganization &&
        this.getOrganization.settings !== null &&
        "onboarding" in this.getOrganization.settings
      ) {
        const install_plugin = this.getOrganization.settings.onboarding.filter(
          (onb: OnboardingStep) => {
            if (onb.key === "install_plugin") {
              return onb;
            }
          }
        );
        if(install_plugin.length > 0) {
          return install_plugin[0]['status'];
        }else {
          return false;
        }
      } else {
        return false;
      }
    },
    getWooCommerceStatus(): boolean {
      if (
        this.getOrganization &&
        this.getOrganization.settings !== null &&
        "onboarding" in this.getOrganization.settings
      ) {
        const import_products = this.getOrganization.settings.onboarding.filter(
          (onb: OnboardingStep) => {
            if (onb.key === "import_products") {
              return onb;
            }
          }
        );
        if(import_products.length > 0) {
          return import_products[0]['status'];
        }else {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  watch: {
    range: {
      handler() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.getReports();
      },
      immediate: true,
    },
  },
  async mounted() {
    if (this) {
      const gridData = JSON.parse(JSON.stringify((this as any).gridData));
      const range = this.$data.range.replace(/ /g, "").replace("to", "/");
      const reportsResponse = await jsonAPI.get(`organization/report/${range}`);
      this.$data.reports = reportsResponse.data as Reports;
      this.$data.currency = this.$data.reports.currency;
      const DATA_COUNT = 7;
      const labels: string[] = [];
      for (let i = DATA_COUNT; i > 0; --i) {
        const now = dayjs().subtract(i, 'd');
        labels.push(now.format('YYYY-MM-DD'));
      }

      this.sales = this.$data.reports.salesTotal;
      const salesData = JSON.parse(JSON.stringify(gridData));
      salesData.labels = labels;
      salesData.datasets.push({
        label: 'Sales',
        data: this.$data.reports.salesByDay,
        borderWidth: 4,
        borderColor: '#9554f1',
        fill: false,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      });
      this.salesGraphConfig.data = salesData;
      const salesGraph = document.getElementById('salesChart');
      if (salesGraph) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        new Chart(salesGraph, this.salesGraphConfig);
      }
      this.revenue = this.$data.reports.revenueTotal;
      this.averageRevenue = this.$data.reports.revenueAverage ?? 0;
      const revenueData = JSON.parse(JSON.stringify(gridData));
      revenueData.labels = labels;
      revenueData.datasets.push({
        label: 'Revenue',
        data: this.$data.reports.revenueByDay,
        borderWidth: 4,
        borderColor: '#f385f0',
        fill: false,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      });
      this.revenueGraphConfig.data = revenueData;
      const revenueChart = document.getElementById('revenueChart');
      if (revenueChart) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        new Chart(revenueChart, this.revenueGraphConfig);
      }
      try {
        const ordersResponse = await jsonAPI.get(
          "/order?per_page=1&page=1&sort=created_at&dir=desc"
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.lastOrder = ordersResponse.data[0];
      } catch (ext) {
        console.log("Failed");
      }
    }
    this.$data.reportsLoading = false;
  },
  methods: {
    getReports(): void {
      const range = this.$data.range.replace(/ /g, "").replace("to", "/");
      jsonAPI.get(`organization/report/${range}`).then((response) => {
        this.$data.reports = response.data as Reports;
        this.$data.reportsLoading = false;
      });
    },
    getLastReceivedOrder: async (
      perPage = 1,
      page = 1,
      sort = "created_at",
      dir = "desc",
      segmentId = "",
      search = ""
    ) => {
      const queryString = `/order?per_page=${perPage}&page=${page}&sort=${sort}&dir=${dir}&segmentId=${segmentId}&search=${search}`;
      const result = await jsonAPI.get(queryString);
      if (result.data) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.lastOrder = result.data[0];
      }
    },
  },
});
